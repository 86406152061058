import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css";
const noticeImagePrefix = "/images/notice"

class NoticeModal extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal = () => {
        this.props.setState(false);
    };

    render() {
        return (
            <div>
                <Modal show={this.props.isOpen} onHide={this.toggleModal}>
                    <Modal.Header>
                        <Modal.Title>In Loving Memory of <br/> Rocco DiAntonio, DMD</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container" style={{textAlign: "center"}}>
                            <div className="row">
                                <div><img src={`${noticeImagePrefix}/rocco.jpg`} style={{width: "100%", borderRadius: "25px"}} alt={"In Loving Memory of Rocco DiAntonio, DMD"}/></div>
                            </div>
                            <br />
                            <div className="row">
                                <div style={{width: "100%"}}>Msgr. Farrell & FBN-TV Class of 2002</div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.toggleModal(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

}

export default NoticeModal;