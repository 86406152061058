const imagePrefix = "/images/gallery"
const imageThumbnailPrefix = `${imagePrefix}/thumb`

export const imageList =
    [
        {
            src: `${imagePrefix}/Bishop_Whalen.jpg`,
            thumbnail: `${imageThumbnailPrefix}/Bishop_Whalen.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/Control_Room.jpg`,
            thumbnail: `${imageThumbnailPrefix}/Control_Room.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/DA_Michael_McMahon.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DA_Michael_McMahon.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/Martha_Stewart.jpg`,
            thumbnail: `${imageThumbnailPrefix}/Martha_Stewart.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/Millionaire_Oct2013.jpg`,
            thumbnail: `${imageThumbnailPrefix}/Millionaire_Oct2013.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/chsaa_hof_brohannon1.jpg`,
            thumbnail: `${imagePrefix}/chsaa_hof_brohannon1.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/chsaa_hof_brohannon2.jpg`,
            thumbnail: `${imagePrefix}/chsaa_hof_brohannon2.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/farrell_bratton_article.jpeg`,
            thumbnail: `${imagePrefix}/farrell_bratton_article.jpeg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378,
        }, {
            src: `${imagePrefix}/farrell_dedication.jpeg`,
            thumbnail: `${imageThumbnailPrefix}/farrell_dedication.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/meredith2015.jpeg`,
            thumbnail: `${imageThumbnailPrefix}/meredith2015.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/megynkelly2018.jpeg`,
            thumbnail: `${imageThumbnailPrefix}/megynkelly2018.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252,
        }, {
            src: `${imagePrefix}/20220526_210057.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20220526_210057.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/20191103_135640.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20191103_135640.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/20191214_172332.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20191214_172332.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20190906_122215.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20190906_122215.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/20190514_083306.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20190514_083306.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        },{
            src: `${imagePrefix}/20220202_082942.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20220202_082942.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20191125_083356.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20191125_083356.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20190513_141055.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20190513_141055.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/fbn_seniors_2020.jpg`,
            thumbnail: `${imageThumbnailPrefix}/fbn_seniors_2020.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20190514_083204.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20190514_083204.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20220308_082509.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20220308_082509.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20200130_092316.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20200130_092316.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        },{
            src: `${imagePrefix}/20190514_081551.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20190514_081551.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/20191016_162046.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20191016_162046.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20220308_082537.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20220308_082537.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20200130_101042.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20200130_101042.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20200130_070917.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20200130_070917.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20190804_115410.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20190804_115410.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20191016_144812.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20191016_144812.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/20190804_160506.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20190804_160506.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/20211201_194646.jpg`,
            thumbnail: `${imageThumbnailPrefix}/20211201_194646.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/DSC01381.JPG`,
            thumbnail: `${imageThumbnailPrefix}/DSC01381.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        },{
            src: `${imagePrefix}/DSC01424.JPG`,
            thumbnail: `${imageThumbnailPrefix}/DSC01424.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01417.JPG`,
            thumbnail: `${imageThumbnailPrefix}/DSC01417.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01416.JPG`,
            thumbnail: `${imageThumbnailPrefix}/DSC01416.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        },  {
            src: `${imagePrefix}/DSC01320.JPG`,
            thumbnail: `${imageThumbnailPrefix}/DSC01320.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01779.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01779.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01221.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01221.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01552.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01552.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC03222.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03222.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02315.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02315.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02116.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02116.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01518.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01518.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01281.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01281.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC03253.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03253.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02429.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02429.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01241.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01241.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01527.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01527.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01447.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01447.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02359.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02359.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01452.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01452.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01268.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01268.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01244.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01244.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC03269.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03269.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01521.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01521.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC03256.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03256.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02363.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02363.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01440.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01440.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC03312.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03312.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02030.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02030.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01510.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01510.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC03259.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03259.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01658.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01658.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC03264.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03264.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/DSC01473.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01473.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC03304.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03304.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02397.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02397.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01660.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01660.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02037.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02037.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/DSC03277.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC03277.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01267.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01267.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/DSC01566.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01566.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02331.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02331.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02126.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02126.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/DSC02708.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02708.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01771.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01771.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01773.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01773.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC01405.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC01405.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC02125.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02125.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/DSC02321.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC02321.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/DSC00467.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00467.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00102.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00102.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00288.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00288.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00061.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00061.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00059.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00059.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00266.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00266.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00064.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00064.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00112.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00112.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00264.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00264.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00213.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00213.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00056.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00056.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00296.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00296.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00057.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00057.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00243.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00243.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00256.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00256.jpg`,
            thumbnailWidth: 252,
            thumbnailHeight: 378
        }, {
            src: `${imagePrefix}/DSC00083.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00083.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00078.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00078.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }, {
            src: `${imagePrefix}/DSC00092.jpg`,
            thumbnail: `${imageThumbnailPrefix}/DSC00092.jpg`,
            thumbnailWidth: 378,
            thumbnailHeight: 252
        }
]