import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css";

class ReleaseModal extends Component {

    constructor(props) {
        super(props);
        this.gitcommitHash = "e94cfd48";
        this.gitBranch = "master";
        this.cibuildtime = "2023-01-06T22:43:38Z"
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal = () => {
        this.props.setState(false);
    };

    render() {
        return (
            <div>
                <Modal show={this.props.isOpen} onHide={this.toggleModal}>
                    <Modal.Header>
                        <Modal.Title>Release Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <dt className="col-sm-6">Git Branch</dt>
                                <dd className="col-sm-6 overflow-auto" style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{this.gitBranch}</dd>
                                <dt className="col-sm-6">Git Commit Hash</dt>
                                <dd className="col-sm-6 overflow-auto" style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{this.gitcommitHash}</dd>
                                <dt className="col-sm-6">Build Date</dt>
                                <dd className="col-sm-6 overflow-auto" style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{this.cibuildtime}</dd>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.toggleModal(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

}

export default ReleaseModal;